import { Amplify, I18n } from "aws-amplify";
import { ThemeProvider } from "@aws-amplify/ui-react";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import config from "./aws-exports";

import { dict } from "./languages"
import App from "./App";

import "./index.css";

localStorage.removeItem('altairSetup')
I18n.setLanguage(navigator.languages[0].split("-")[0]);
I18n.putVocabularies(dict);
Amplify.configure(config);

const theme = {
  name: 'multiCompanyCSS',
  tokens: {
    components: {
      button: {
         backgroundColor: { value: '#373A40'  },
         primary:{
           backgroundColor: { value: '#373A40' },
           color: {value: '#19D3DA'},
           _hover:{
             backgroundColor: { value: '#19D3DA' },
             color: {value: '#373A40'}
           }
         }
      },
      passwordfield: {
        button: {
          color: { value: '#373A40' },
          backgroundColor: { value: '#373A40' },
          _hover: {
            border:0,
            borderColor: {value: 'transparent'},
            backgroundColor: { value: '#19D3DA' },
            color: { value: '#373A40' },
          },
          _active: {
            backgroundColor: { value: '#373A40' },

            borderColor: {value: 'transparent'},

          },
          _focus: {
            color: { value:  '#373A40'  },
            borderColor: {value: 'transparent'},

          },
        },
      },
      fieldcontrol: {
        borderRadius: 0,
        border: 0,
        borderColor: {value: 'transparent'},
        color: { value: '#373A40'},
      },

    }
  }
}
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <ThemeProvider theme={theme}>
    <HelmetProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </HelmetProvider>
  </ThemeProvider>
);
