import { useEffect, lazy, Suspense } from 'react';
import { useLocation } from 'react-router-dom';

import '@aws-amplify/ui-react/styles.css';
import './App.css';
import ThemeProvider from './theme';

const Router = lazy(() => import('./routes'));

export default function App() {
  const { pathname } = useLocation();
  console.log('pathname', pathname)
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <ThemeProvider>
      <Suspense fallback={<div></div>}>
          <Router/>
      </Suspense>
    </ThemeProvider>
  );
}
